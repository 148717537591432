import React from "react";
import Header from "./Header";
import Product1 from "../assets/images/newProd/prod1.jpeg";
import Product2 from "../assets/images/newProd/prdo2.jpeg";
import Product4 from "../assets/images/newProd/prod4.jpeg";
import Product5 from "../assets/images/newProd/prod5.jpeg";
import Product6 from "../assets/images/newProd/prod6.jpeg";
import Product7 from "../assets/images/newProd/prod7.jpeg";
import Product8 from "../assets/images/newProd/prod8.jpeg";
import Product9 from "../assets/images/newProd/prod9.jpeg";
import Product10 from "../assets/images/newProd/prod10.jpeg";
import Product11 from "../assets/images/newProd/prod11.jpeg";
import Product12 from "../assets/images/newProd/prod12.jpeg";
import Product13 from "../assets/images/newProd/prod13.jpeg";
import Product14 from "../assets/images/newProd/prod14.jpeg";
import Product15 from "../assets/images/newProd/prod15.jpeg";

import new1 from "../assets/19/191.JPG";
import new2 from "../assets/19/192.JPG";
import new3 from "../assets/19/193.JPG";
import new4 from "../assets/19/194.JPG";
import new5 from "../assets/19/195.JPG";
import new6 from "../assets/19/196.JPG";
import new7 from "../assets/19/197.JPG";
import new8 from "../assets/19/198.JPG";
import new9 from "../assets/19/199.JPG";

const OurProducts = () => {
  const products = [
    {
      id: 1,
      name: "Wall Furniture",
      href: "#",
      imageSrc: Product1,
      imageAlt:
        "Tall slender porcelain bottle with natural clay textured body and cork stopper.",
    },
    {
      id: 1,
      name: "Sofa",
      href: "#",
      imageSrc: Product2,
      imageAlt:
        "Tall slender porcelain bottle with natural clay textured body and cork stopper.",
    },
    {
      id: 1,
      name: "Study Material",
      href: "#",
      imageSrc: Product4,
      imageAlt:
        "Tall slender porcelain bottle with natural clay textured body and cork stopper.",
    },
    {
      id: 1,
      name: "Wall Furniture",
      href: "#",
      imageSrc: Product5,
      imageAlt:
        "Tall slender porcelain bottle with natural clay textured body and cork stopper.",
    },
    {
      id: 1,
      name: "Corner",
      href: "#",
      imageSrc: Product6,
      imageAlt:
        "Tall slender porcelain bottle with natural clay textured body and cork stopper.",
    },
    {
      id: 1,
      name: "Corner",
      href: "#",
      imageSrc: Product7,
      imageAlt:
        "Tall slender porcelain bottle with natural clay textured body and cork stopper.",
    },
    {
      id: 1,
      name: "Bed",
      href: "#",
      imageSrc: Product8,
      imageAlt:
        "Tall slender porcelain bottle with natural clay textured body and cork stopper.",
    },
    {
      id: 1,
      name: "Corner",
      href: "#",
      imageSrc: Product9,
      imageAlt:
        "Tall slender porcelain bottle with natural clay textured body and cork stopper.",
    },
    {
      id: 1,
      name: "Kitchen",
      href: "#",
      imageSrc: Product10,
      imageAlt:
        "Tall slender porcelain bottle with natural clay textured body and cork stopper.",
    },
    {
      id: 1,
      name: "Wooden Material",
      href: "#",
      imageSrc: Product11,
      imageAlt:
        "Tall slender porcelain bottle with natural clay textured body and cork stopper.",
    },
    {
      id: 1,
      name: "Kitchen",
      href: "#",
      imageSrc: Product12,
      imageAlt:
        "Tall slender porcelain bottle with natural clay textured body and cork stopper.",
    },
    {
      id: 1,
      name: "Kitchen",
      href: "#",
      imageSrc: Product13,
      imageAlt:
        "Tall slender porcelain bottle with natural clay textured body and cork stopper.",
    },
    {
      id: 1,
      name: "Kitchen",
      href: "#",
      imageSrc: Product14,
      imageAlt:
        "Tall slender porcelain bottle with natural clay textured body and cork stopper.",
    },
    {
      id: 1,
      name: "Kitchen",
      href: "#",
      imageSrc: Product15,
      imageAlt:
        "Tall slender porcelain bottle with natural clay textured body and cork stopper.",
    },
    {
      id: 1,
      name: "TV SET",
      href: "#",
      imageSrc: new1,
      imageAlt:
        "Tall slender porcelain bottle with natural clay textured body and cork stopper.",
    },
    {
      id: 1,
      name: "Recliner",
      href: "#",
      imageSrc: new2,
      imageAlt:
        "Tall slender porcelain bottle with natural clay textured body and cork stopper.",
    },
    {
      id: 1,
      name: "Recliner",
      href: "#",
      imageSrc: new3,
      imageAlt:
        "Tall slender porcelain bottle with natural clay textured body and cork stopper.",
    },
    {
      id: 1,
      name: "Dining Table",
      href: "#",
      imageSrc: new4,
      imageAlt:
        "Tall slender porcelain bottle with natural clay textured body and cork stopper.",
    },
    {
      id: 1,
      name: "Dining Table",
      href: "#",
      imageSrc: new5,
      imageAlt:
        "Tall slender porcelain bottle with natural clay textured body and cork stopper.",
    },
    {
      id: 1,
      name: "Study Table",
      href: "#",
      imageSrc: new6,
      imageAlt:
        "Tall slender porcelain bottle with natural clay textured body and cork stopper.",
    },
    {
      id: 1,
      name: "Recliner",
      href: "#",
      imageSrc: new7,
      imageAlt:
        "Tall slender porcelain bottle with natural clay textured body and cork stopper.",
    },
    {
      id: 1,
      name: "Sofas",
      href: "#",
      imageSrc: new8,
      imageAlt:
        "Tall slender porcelain bottle with natural clay textured body and cork stopper.",
    },
    {
      id: 1,
      name: "Sofas",
      href: "#",
      imageSrc: new9,
      imageAlt:
        "Tall slender porcelain bottle with natural clay textured body and cork stopper.",
    },
  ];
  return (
    <main>
      <Header />
      <h2 className='text-center p-6 text-4xl font-semibold'>Our Products</h2>
      <div className='bg-white'>
        <div className='mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8'>
          <h2 className='sr-only'>Products</h2>

          <div className='grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8'>
            {products.map((product) => (
              <a key={product.id} href={product.href} className='group'>
                <div className='aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7 h-[40vh]'>
                  <img
                    alt={product.imageAlt}
                    src={product.imageSrc}
                    className='h-full w-full object-cover object-center group-hover:opacity-75 object-cover'
                  />
                </div>
                <h3 className='mt-4 text-sm text-gray-700'>{product.name}</h3>
                <p className='mt-1 text-lg font-medium text-gray-900'>
                  {product.price}
                </p>
              </a>
            ))}
          </div>
        </div>
      </div>
    </main>
  );
};

export default OurProducts;

import React from "react";

const About = () => {
  return (
    <div>
      <section className='bg-white dark:bg-gray-900'>
        <div className='gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6'>
          <div className='font-light text-gray-500 sm:text-lg dark:text-gray-400'>
            <h2 className='mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white'>
              We didn't reinvent the wheel
            </h2>
            <p className='mb-4'>
              At our furniture shop, we specialize in crafting high-quality,
              custom-made furniture that turns your vision into reality. Whether
              you're looking for a timeless piece that adds charm to your home
              or a modern design that fits seamlessly into your space, we've got
              you covered.
            </p>
            <p>
              We are strategists, designers and developers. Innovators and
              problem solvers. Small enough to be simple and quick.
            </p>
          </div>
          <div className='grid grid-cols-2 gap-4 mt-8'>
            <img
              className='w-full rounded-lg'
              src='https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-2.png'
              alt='office content 1'
            />
            <img
              className='mt-4 w-full lg:mt-10 rounded-lg'
              src='https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-1.png'
              alt='office content 2'
            />
          </div>
        </div>
      </section>
      <section className='bg-[#111827] text-white pb-6'>
        <div class='bg-gray-200 px-2 ' />

        <div id='features' class='mx-auto max-w-6xl'>
          <p class='text-center text-5xl font-semibold leading-7 text-primary-500'>
            Why Choose Us?
          </p>
          <ul class='mt-16 grid grid-cols-1 gap-6 text-center text-slate-700 md:grid-cols-3'>
            <li class='rounded-xl bg-white px-6 py-8 shadow-sm'>
              <img
                src='https://www.svgrepo.com/show/530438/ddos-protection.svg'
                alt=''
                class='mx-auto h-10 w-10'
              />
              <h3 class='my-3 font-display font-medium'>Quality Materials</h3>
              <p class='mt-1.5 text-sm leading-6 text-secondary-500'>
                We source only the best materials to ensure durability and
                elegance in every piece.
              </p>
            </li>
            <li class='rounded-xl bg-white px-6 py-8 shadow-sm'>
              <img
                src='https://www.svgrepo.com/show/530442/port-detection.svg'
                alt=''
                class='mx-auto h-10 w-10'
              />
              <h3 class='my-3 font-display font-medium'>
                Expert Craftsmanship
              </h3>
              <p class='mt-1.5 text-sm leading-6 text-secondary-500'>
                Our team of skilled artisans has years of experience in creating
                beautiful, functional furniture.
              </p>
            </li>
            <li class='rounded-xl bg-white px-6 py-8 shadow-sm'>
              <img
                src='https://www.svgrepo.com/show/530444/availability.svg'
                alt=''
                class='mx-auto h-10 w-10'
              />
              <h3 class='my-3 font-display font-medium'>
                Personalized Service:
              </h3>
              <p class='mt-1.5 text-sm leading-6 text-secondary-500'>
                We work with you to design and create furniture that meets your
                specific needs and preferences.
              </p>
            </li>
            <li class='rounded-xl bg-white px-6 py-8 shadow-sm'>
              <a href='/pricing' class='group'>
                <img
                  src='https://www.svgrepo.com/show/530440/machine-vision.svg'
                  alt=''
                  class='mx-auto h-10 w-10'
                />
                <h3 class='my-3 font-display font-medium group-hover:text-primary-500'>
                  Satisfaction Guaranteed
                </h3>
                <p class='mt-1.5 text-sm leading-6 text-secondary-500'>
                  We take pride in our work, and we’re committed to making sure
                  you’re delighted with your new furniture.
                </p>
              </a>
            </li>
            <li class='rounded-xl bg-white px-6 py-8 shadow-sm'>
              <a href='/templates' class='group'>
                <img
                  src='https://www.svgrepo.com/show/530450/page-analysis.svg'
                  alt=''
                  class='mx-auto h-10 w-10'
                />
                <h3 class='my-3 font-display font-medium group-hover:text-primary-500'>
                  200+ Products
                </h3>
                <p class='mt-1.5 text-sm leading-6 text-secondary-500'>
                  We offer many furniture covering areas such as writing,
                  education, lifestyle and creativity to inspire your potential.{" "}
                </p>
              </a>
            </li>
            <li class='rounded-xl bg-white px-6 py-8 shadow-sm'>
              <a href='/download' class='group'>
                <img
                  src='https://www.svgrepo.com/show/530453/mail-reception.svg'
                  alt=''
                  class='mx-auto h-10 w-10'
                />
                <h3 class='my-3 font-display font-medium group-hover:text-primary-500'>
                  Use Anywhere
                </h3>
                <p class='mt-1.5 text-sm leading-6 text-secondary-500'>
                  Our product is compatible with multiple platforms including
                  Web, Chrome, Windows and Mac, you can use MagickPen anywhere.
                </p>
              </a>
            </li>
          </ul>
        </div>

        <div></div>
      </section>
    </div>
  );
};

export default About;

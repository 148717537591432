import React from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Features from "../components/Features";
import NewItems from "../components/NewItems";
import FeaturesSecond from "../components/FeaturesSecond";
import Products from "../components/Products";
import Testimonial from "../components/Testimonial";
import NewsLetters from "../components/NewsLetters";
import Footer from "../components/Footer";

const Home = () => {
  return (
    <div>
      <Hero />
      <Features />
      <NewItems />
      <FeaturesSecond />
      <Products />
      <Testimonial />
      <NewsLetters />
    </div>
  );
};

export default Home;

import Footer from "./components/Footer";
import Header from "./components/Header";
import Contact from "./components/Contact";
import Home from "./pages/Home";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import About from "./components/About";
import Products from "./components/Products";
import OurProducts from "./components/OurProducts";

function App() {
  return (
    <div className='w-full mx-auto bg-white'>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/products' element={<OurProducts />} />
          <Route path='/features' element={<Products />} />
          <Route path='/contact' element={<Contact />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
